.ModalImage {
    width: 270px;
    height: 250px;
    border-radius: 10px;
}
.libraryPhoto {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    align-items: center;
    justify-content: space-evenly;
}
@media screen and (max-width:768px) {
    .ModalImage {
        width: 100%;
    }
    .libraryPhoto div {
        width: 100%;
    }
}