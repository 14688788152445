.AppFooter {
  padding-bottom: 10px;
}
.footerText {   
    margin-bottom: 10px; 
    background-color: black;
    border-radius: 12px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: white;
}
@media only screen and (max-width: 600px) {
    .footerText {
      flex-direction: column;
      row-gap: 24px;
      text-align: center;
    }
}
@media only screen and (max-width: 380px) {
  .footerText {
      padding: 10px 10px;
  }
}
