.works_about_section {
    width: 100%;
    padding: 20px 0 20px;
}
.works_about_block {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}
.works_about_img_block {
    max-width: 596px;
    width: 100%;
    height: 522px;
    position: relative;
}
.works_about_info_btn_block {
    padding-top: 67px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    max-width: 596px;
    width: 100%;
    font-family: "Bold";
}
.works_about_info_title {
    font-family: "Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    text-transform: capitalize;
    color: White;
    max-width: 451px;
}
.works_about_info_text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: white;
}
.support_for_busines_section {
    width: 100%;
    padding-bottom: 50px;
}
.support_for_busines_block {
    display: flex;
    column-gap: 122px;
    row-gap: 30px;
}
.support_for_busines_info_block {
    max-width: 600px;
    width: 100%;
}
.support_for_busines_info_top {
    font-family: "Bold";
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 35px;
}
.support_for_busines_info_title {
    font-family: "Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.support_property_block {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 50px;
    column-gap: 30px;
}
.support_property_block p {
    display: flex;
    column-gap: 15px;
    align-items: center;
    font-family: "Light";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.support_for_busines_img_blok {
    max-width: 465px;
    height: 504px;
    width: 100%;
    position: relative;
}
.black {
    width: 478.32px;
    height: 453.41px;
    background: #273540;
    transform: rotate(-20.46deg);
    border-radius: 50%;
    position: absolute;
    bottom: -40px;
    left: -15px;
}
.blue {
    width: 380px;
    height: 399px;
    background: #104CBA;
    border-radius: 50%;
    position: absolute;
    bottom: 45px;
    left: -20px;
}
.img_bg {
    position: absolute;
    top: 0;
}

@media screen and (max-width:1024px) {
    .support_for_busines_block {
        padding-left: 20px;
    }
    .black {
        width: 389.32px;
        height: 399.41px;
        bottom: -8px;
        left: 19px;
    }
    .support_property_block {
        grid-template-columns: repeat(1,1fr);
        row-gap: 30px;
    }
}
@media screen and (max-width:1020px) {
    .support_for_busines_info_title {
        font-size: 30px;
        line-height: 40px;
    }
    .blue {
        width: 263px;
        height: 238px;
        bottom: 217px;
    }
    .black {
        width: 252.32px;
        height: 225.41px;
        bottom: 172px;
    }
    .img_bg {
        width: 364px;
        height: 317px;
    }
    .support_for_busines_block {
        column-gap: 50px;
    }
    .support_for_busines_section {
        padding-bottom: 0;
    }
    .support_for_busines_img_blok {
        height: 410px;
    }
}
@media screen and (max-width:768px) {
    .support_for_busines_info_top {
        margin-bottom: 20px;
    }
}
@media screen and (max-width:755px) {
    .works_about_block {
        row-gap: 20px;
        flex-direction: column;
    }
    .works_about_info_btn_block {
        padding-top: 0;
    }
    .works_about_section {
        padding: 30px 0 35px;
    }
    .works_about_info_title {
        font-size: 28px;
        line-height: 30px;
    }
    .works_about_info_text {
        font-size: 15px;
        line-height: 21px;
    }
    .works_about_img_block {
        height: 300px;
    }
    .support_for_busines_section {
        padding-top: 50px;
    }
}
@media screen and (max-width:600px) {
    .support_for_busines_img_blok {
        display: none;
    }
    .support_for_busines_info_title {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 10px;
    }
    .support_for_busines_info_top {
        margin-bottom: 10px;
    }
    .support_property_block p {
        font-size: 16px;
    }
    .support_for_busines_section {
        padding-top: 0;
    }
    .works_about_section {
        padding: 20px 0 20px;
    }
    .support_for_busines_block {
        padding-left: 0;
    }
}
@media screen and (max-width:380px) {
    .works_about_info_title {
        font-size: 25px;
        line-height: 25px;
    }
    .works_about_info_btn_block {
        row-gap: 15px;
    }
    .support_for_busines_info_top {
        font-size: 16px;
    }
    .support_for_busines_info_title {
        font-size: 20px;
        line-height: 25px;
    }
}