.gensec {
    height: 350px;
    border-radius: 10px;
    position: relative;
}
.gensec img {
    border-radius: 10px;
}
.general_title {
    font-style: "Bold";
    font-weight: bold;
    font-size: 50px;
    color: #FFFFFF;
}
.page_welcome_info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 680px) {
    .gensec {
        height: 150px;
    }
    .general_title {
        font-size: 28px;
    }
}
@media only screen and (max-width: 380px) {
    .general_title {
        font-size: 18px;
    }
}