code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Light"; 
  src: local("NotoSansArmenian-Regular"), url("./Assest/Fonts/NotoSansArmenian-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Bold"; 
  src: local("NotoSansArmenian-Regular"), url("./Assest/Fonts/NotoSansArmenian-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBold"; 
  src: local("NotoSansArmenian-Regular"), url("./Assest/Fonts/NotoSansArmenian-SemiBold.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Light";
}
*:focus {
  outline: none;
}
a {
  text-decoration: none;
  color: black;
  transition: all 300ms ease-out;
}
a:hover {
  transition: all 300ms ease-in;
} 
body {
  min-height: 100vh;
  min-width: 260px;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
  background-color: red;
  background-image: linear-gradient(to bottom right, red, rgb(87, 85, 85));
  background-size: cover;
  background-repeat: no-repeat;
}
img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.wrapper {
  max-width: 1260px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 380px) {
  .wrapper {
    padding: 0 10px;
  }
}