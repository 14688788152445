.aboutustitle {
    color: white;
    font-family: "SemiBold";
    font-weight: 500;
    font-size: 24px;
    margin-top: 10px;
}
.hvhh {
    color: white;
    font-family: "Light";
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
}
.texto {
    font-family: "Light";
    font-size: 12px;
    color: white;
    margin-bottom: 10px;
}
.address_block_for_contact {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
}
.addr_block {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.addr_title {
    font-family: "Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
    color: #0e7ecd;
}
.addr_info address {
    font-family: "Bold";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: white;
}
.addr_block span {
    width: 53px;
    height: 53px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addr_info a {
    font-family: "Light";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: white;
}
.addr_info a:hover {
    color: #0e7ecd;
}
@media only screen and (max-width: 380px) {
    .aboutustitle {
        font-size: 22px;
    }
}