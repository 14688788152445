.AppHeader {
    padding-top: 10px;
}
.headerBig {
    background-color: black;
    border-radius: 12px;
    padding: 12px 24px;
}
.headerBlock {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 80px;
}
.headerLogo {
    width: 50px;
}
.menuWeb {
    display: flex;
    column-gap: 25px;
}
.menuMob {
    display: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
}
.MobMenu {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
}
.menu_link {
    width: max-content;
    cursor: pointer;
    font-style: normal;
    font-family: "Bold";
    font-weight: bold;
    font-size: 16px;
    color: white;
    padding: 12px;
    border-radius: 12px;
}
.menu_link:hover {
    background-color: #0e7ecd;
}
.closeMenu {
    display: none;
}
.openMenu {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background-color: black;
}
@media only screen and (max-width: 680px) {
    .menuWeb {
        display: none;
    }
    .menuMob {
        display: block;
    }
    .headerBlock {
        justify-content: space-between;
    }
    .headerLogo {
        width: 40px;
    }
}
@media only screen and (max-width: 380px) {
    .headerBig {
        padding: 10px 10px;
    }
}
